"use client";
import Link from "next/link";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { useState, useEffect } from "react";
import { NavItem } from "./constants/NavItem";
import { useSidebar } from "@/hooks/useSidebar";

interface SideNavProps {
  items: NavItem[];
  setOpen?: (open: boolean) => void;
  className?: string;
}

export function SideNav({ items, setOpen, className }: SideNavProps) {
  const { isOpen, toggle } = useSidebar();

  const [openItem, setOpenItem] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      console.log(`effect  window.location=${window.location.pathname}`);
      const openByPath = items.findIndex(
        (x) =>
          x.href === window.location.pathname ||
          window.location.pathname.startsWith(x.href),
      );
      if (openByPath > 0) {
        setOpenItem(openByPath);
      }
    }, 200);
  });
  return (
    <nav className="space-y-2">
      {items.map((item, i) => (
        <Link
          href={item.href}
          key={`${item.title}-${i}-link`}
          onClick={() => {
            console.log("click on ", `${item.title}-${i}`);
            setOpenItem(i);
            console.log("set open item ", i);
          }}
          className={cn(
            buttonVariants({ variant: "ghost" }),
            "group relative flex h-12 justify-start",

            openItem == i && "bg-muted bg-gray-400 font-bold hover:bg-muted",
          )}
        >
          <item.icon
            className={cn("h-5 w-5", item.color)}
            key={`${item.title}-${i}-icon`}
          />
          {isOpen ? (
            <span
              className={cn(
                "text-base text-base duration-200 ml-1",
                !(openItem == i),
              )}
              key={`${item.title}-${i}-span`}
            >
              {item.title}{" "}
            </span>
          ) : (
            <></>
          )}
        </Link>
      ))}
    </nav>
  );
}
