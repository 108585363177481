"use client";

import React from "react";

import { useSidebar } from "@/hooks/useSidebar";
import { cn } from "@/lib/utils";
import Footer from "./Footer";

export default function Wrapper({
  children,
  withFooter,
}: {
  children: React.ReactNode;
  withFooter?: boolean;
}) {
  const { isOpen, toggle } = useSidebar();

  return (
    <nav
      className={cn(
        "flex",
        "flex-col",
        "min-h-full",
        "duration-500",
        "w-full",
        "max-h-full",
        // "overflow-y-auto",
        isOpen ? "md:pl-52" : "md:pl-[78px]", // This can cause performance issues, intended way is to use transform
      )}
    >
      <section className="mt-2 min-h-max self-center w-full max-w-[2000px] min-h-full">
        {children}
      </section>
      {withFooter && <Footer />}
    </nav>
  );
}
